<template>
  <div class="full_popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-8 col-lg-6 col-xxl-6 p-0 card_item">
        <div class="row h-100 justify-content-center">
          <div class="col-12 p-3 pb-0">
            <div class="row m-0 justify-content-center holder">
              <div class="bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 p-3 above">
                <div class="row mb-5 d-md-none">
                  <div class="col-12 text-center">
                    <Button color="dark" size="small" class="white-text" btnText="Back to Debit Orders" icon2="arrow" @buttonClicked="$emit('close')">
                      <template v-slot:iconStart>
                        <IconArrowBack size="size16" />
                      </template>
                    </Button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-10 offset-md-1 pt-2">
                    <div class="row justify-content-center">
                      <div class="col-auto text-center p-0 card_title">
                        DEBIT ORDER: {{item.debitOrderID}}
                        <div class="row m-0 justify-content-center" >
                          <div class="col-12 text-center pt-3 border_bottom">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-end cursor d-none d-md-block" @click="$emit('close')">
                    <ios-close-icon class="inline-icon green-text-dark" />
                  </div>
                </div>
                <div class="row m-0 justify-content-center" >
                  <div class="col-md-11 px-0 my-3 card_item_inner">
                    <div class="row mx-0 h-100 justify-content-center">
                      <div class="col-12 p-0">
                        <div class="row m-0 justify-content-center">
                          <div class="col-12 p-0 border_top" v-for="(donation, key) in item.donations" :key="key">
                            <div class="row m-0 align-items-center borders py-2">
                              <!--IMAGE-->
                              <div class="col-12 text-center col-md-auto px-4">
                                <Image :imageName="donation.project.imageUrl" width="40" theClass="item_img" v-if="donation.project.imageUrl" />
                                <!-- <img :src="url + '/static/uploads/images/' + donation.project.imageUrl"  class="item_img" v-if="donation.project.imageUrl"> -->
                                <img src="../assets/images/Bismillah-Khutbah-1.png"  class="item_img" v-else>
                              </div>
                              <!--END IMAGE-->
                              <div class="col-12 col-md p-0 text-center text-md-start mt-2  mt-md-0">
                                <div class="row m-0">
                                  <div class="col-md-12 p-0 green-text bold item">
                                    {{donation.project.description}}
                                  </div>
                                  <div class="col-md-12 p-0 font12">
                                    {{ donation.currency.symbol }} {{ parseFloat(Math.round(donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}} {{ donation.donationType.description }}
                                  </div>
                                </div>
                              </div>
                              <div class="col col-md-auto text-center text-md-end bold mt-3 mt-md-0 pe-4 item">
                                {{ donation.currency.symbol }} {{ parseFloat(Math.round(donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--TOTAL/FREQUENCY/DATE-->
                <div class="row m-0 justify-content-center">
                  <!--DESKTOP-->
                  <div class="col-11 px-0 my-3 d-none d-md-block">
                    <div class="row align-items-center">
                      <div class="col-4 text-center green-text">
                        <Button color="grey" width="100" :btnText="displayTotal" />
                      </div>
                      <div class="col-4 text-center">
                        <Button color="grey" width="100" :btnText="frequency" />
                      </div>
                      <div class="col-4 text-center green-text">
                        <Button color="grey" width="100" :btnText="date" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-4 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          DONATION TOTAL
                        </div>
                      </div>
                      <div class="col-4 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          FREQUENCY
                        </div>
                      </div>
                      <div class="col-4 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          NEXT PAYMENT
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--END DESKTOP-->
                  <!--MOBILE-->
                  <div class="col-12 px-0 my-3 d-md-none">
                    <div class="row align-items-center">
                      <div class="col-6 pe-1 text-center green-text">
                        <Button color="grey" width="100" :btnText="frequency" />
                      </div>
                      <div class="col-6 ps-1 text-center green-text">
                        <Button color="grey" width="100" :btnText="date" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-6 pe-1 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          FREQUENCY
                        </div>
                      </div>
                      <div class="col-6 ps-1 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          NEXT PAYMENT
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-12 text-center green-text">
                        <Button color="green" width="100" :btnText="displayTotal" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          DONATION TOTAL
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--END MOBILE-->
                </div>
                <!--END TOTAL/FREQUENCY/DATE-->
              </div>
            </div>
          </div>
          <!--BUTTONS-->
          <div class="col-12 px-4 align-self-end background-green">
            <div class="row mx-0 py-3 align-items-center justify-content-center">
              <div class="col-md-4 px-1 mb-2 mb-md-0 order-last order-md-first" >
                <Button color="red" size="" width="100" btnText="Cancel" icon="arrow" @buttonClicked="$emit('deleteOrder')">
                  <ios-close-circle-outline-icon class="inline-icon" />
                </Button>
              </div>
              <div class="col-md-4 px-1 mb-2 mb-md-0" >
                <Button color="green_light" size="" width="100" btnText="Edit Donation" icon="arrow" @buttonClicked="editDebit">
                  <IconEdit size="size16"/>
                </Button>
              </div>
              <div class="col-md-4 px-1 mb-2 mb-md-0" >
                <Button color="green_light" size="" width="100" btnText="Edit Bank Details" icon="arrow" @buttonClicked="editBank">
                  <IconBank size="size16"/>
                </Button>
              </div>
            </div>
          </div>
          <!--END BUTTONS-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

import { DateTime } from 'luxon'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    Image: defineAsyncComponent(() => import('../components/Image.vue')),
    IconBank: defineAsyncComponent(() => import('../components/icons/IconBank.vue')),
    IconEdit: defineAsyncComponent(() => import('../components/icons/IconEdit.vue')),
    IconArrowBack: defineAsyncComponent(() => import('../components/icons/IconArrowBack.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    'ios-close-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close-circle-outline.vue'))
  },
  name: 'Debit Order View Popup',
  props: ['item'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL
    }
  },
  methods: {
    editDebit () {
      this.$emit('edit')
    },
    editBank () {
      this.$emit('editBank')
    }
  },
  computed: {
    ...mapGetters(['user']),
    displayTotal () {
      return this.defaultCurrency + ' ' + parseFloat(Math.round(this.item.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    date () {
      const debitOrderDay = this.item.debitOrderDay
      const startMonth = DateTime.fromMillis(this.item.startDate).month
      const startYear = DateTime.fromMillis(this.item.startDate).year
      const startDate = DateTime.fromObject({ year: startYear, month: startMonth, day: debitOrderDay }).toMillis()
      const now = DateTime.now().ts
      const frequency = this.item.frequency
      let nextPaymentDate = null
      if (now > startDate) {
        if (frequency === 'DAILY') {
          nextPaymentDate = DateTime.now().startOf('day').plus({ days: 1 })
        } else if (frequency === 'WEEKLY') {
          const diff = Math.ceil(Math.abs(DateTime.fromMillis(startDate).diffNow('weeks').weeks))
          nextPaymentDate = DateTime.fromMillis(startDate).plus({ weeks: diff })
        } else if (frequency === 'MONTHLY') {
          const diff = Math.ceil(Math.abs(DateTime.fromMillis(startDate).diffNow('months').months))
          nextPaymentDate = DateTime.fromMillis(startDate).plus({ months: diff })
        }
      } else {
        nextPaymentDate = DateTime.fromMillis(startDate)
      }
      return DateTime.fromMillis(nextPaymentDate.ts).toFormat('yyyy-MM-dd')
    },
    frequency () {
      const lowercase = this.item.frequency.toLowerCase()
      return lowercase[0].toUpperCase() + lowercase.slice(1)
    },
    method () {
      const lowercase = this.item.donorBankAccount.bankName.toLowerCase()
      return lowercase[0].toUpperCase() + lowercase.slice(1)
    }
  }
}
</script>
<style scoped>
.full_popup {
  position: fixed;
  z-index: 98;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
</style>
